/* eslint-disable @typescript-eslint/no-explicit-any */

export function clearObject<Type>(
  object: Type extends Record<string, unknown> ? Type : any,
): Type {
  const newObject: Type = {} as Type;

  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      newObject[key] = clearObject(value);
    } else if (value !== null && value !== undefined && value !== '') {
      newObject[key] = value;
    }
  });

  return newObject;
}
